import {convertToResponse} from "./BaseResponse";

export default class BaseRequest {

    constructor(props) {
        this.props = props
    }

    LOCAL_SERVER = "http://127.0.0.1:8080"
    DEV_SERVER = "https://dev.api.getsquareone.co.nz"
    PROD_SERVER = "https://api.getsquareone.co.nz"
    SERVER_URL = this.PROD_SERVER

    addHeaders(requestOptions = {}): Promise {
        return new Promise((resolve, reject) => {
            if (requestOptions.headers == null) {
                requestOptions.headers = {}
            }

            if (!(requestOptions.body instanceof FormData)) {
                requestOptions.headers['Content-Type'] = 'application/json'
            }

            resolve(requestOptions)
        })
    }

    executeRequest(url, method, body) : Promise {
        const requestOptions = {
            method: method
        };

        console.log('Executing: ' + url)
        console.log('Body' + JSON.stringify(body))

        if(body !== undefined && body !== null) {
            if(body instanceof FormData) {
                requestOptions.body = body
            } else {
                requestOptions.body = JSON.stringify(body)
            }
        }

        return this.addHeaders(requestOptions).then((requestOptions) => {
            return fetch(this.SERVER_URL + url, requestOptions)
                .then(async (result) => {
                    console.log('Response for: ' + url)
                    console.log(result)
                    let json
                    try {
                        json = await result.json()
                    } catch (e) {
                        console.log('Error parsing body of ' + url + ' | ' + e)
                    }
                    console.log(json)
                    if(json.code === "2000") {
                        return convertToResponse(json.data, null, result.status)
                    } else {
                        return convertToResponse(null, json.data, result.status)
                    }
                }, (error) => {
                    return convertToResponse(null, error)
                })
        })
    }

}