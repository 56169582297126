import {Col, Container, Image, Row} from "react-bootstrap";
import CardLoading from "../common/CardLoading";
import header from "../../resources/header-send-it.svg";
import welcomePhoto from "../../resources/welcome-photo.svg"
import welcomeReview from "../../resources/welcome-review.svg"
import buttonAppleStore from "../../resources/button-apple-store.svg"
import buttonGooglePlay from "../../resources/button-google-play.svg"
import "./WelcomeView.css"
import {useLocation} from "react-router";

export default function WelcomeView(props) {

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const error = params.get("error")

    return (
        <Container className="deposit-background vh-100 vw-100 overflow-scroll">
            <main className="mx-auto ml-sm-auto px-md-4 pb-4 w-100 h-100 overflow-scroll">
                <Col className="deposit-col mx-auto">
                    <Row className={"position-relative justify-content-center"}>
                        <Image
                            className={"deposit-header"}
                            src={header}
                        />
                    </Row>
                    <Row>
                        <span className={"welcome-title mt-2"}>
                            {
                                error ?
                                    "OOPS" : "WELCOME"
                            }
                        </span>
                        <span
                            className={"welcome-subtitle"}>
                            {
                                error ?
                                    "THAT’S NOT A VALID LINK. PLEASE TRY AGAIN OR DOWNLOAD SQUAREONE TO GET STARTED"
                                    : "TO GET STARTED ASK FOR A PAYMENT LINK OR DOWNLOAD SQUAREONE"
                            }
                        </span>
                    </Row>
                    <Row className={"justify-content-center"}>
                        <Image
                            className={"welcome-photo p-0"}
                            src={welcomePhoto}/>
                        <Image
                            className={"welcome-review p-0"}
                            src={welcomeReview}/>
                    </Row>
                    <Row className={"mt-5 p-0 justify-content-center"}>
                        <Image
                            className={"button-store p-0 mx-2"}
                            onClick={() => {
                                openURL("https://apps.apple.com/nz/app/id1553399999")
                            }}
                            src={buttonAppleStore}/>
                        <Image
                            className={"button-store p-0 mx-2"}
                            onClick={() => {
                                openURL("https://play.google.com/store/apps/details?id=app.getsquareone")
                            }}
                            src={buttonGooglePlay}/>
                    </Row>
                </Col>
            </main>
        </Container>
    )

    function openURL(url) {
        window.location = url
    }
}