import {Button, Card, Col, Container, FormControl, InputGroup, Row, Image, Alert} from "react-bootstrap";
import ReactToPrint from "react-to-print";
import {QRCode} from 'react-qrcode-logo';
import useViewModel from "./DepositDetailsViewModel";
import {createRef, useEffect, useRef, useState} from "react";
import CardLoading from "../common/CardLoading";
import {iconUrlOrDefault} from "../../common/UIUtils";
import {isTextNullOrEmpty} from "../../common/TextUtils";
import header from "./../../resources/header-send-it.svg";
import currency from "./../../resources/currency.svg";
import avatarStar from "./../../resources/avatar-star.svg";
import senditLogo from "./../../resources/logo-sendit.svg"
import logo from "./../../logo.svg"
import share from "./../../resources/button-share.svg"
import './DepositDetailsView.css'

import {useLocation, useNavigate, useParams} from 'react-router';
import appleLogo from "../../resources/apple-logo.svg";
import buttonGooglePlay from "../../resources/button-google-play.png";
import buttonAppleStore from "../../resources/button-apple-store.png";

export default function DepositDetailsView(props) {
    const {referenceCode} = useParams();
    let componentRef = useRef()
    let printButtonRef = useRef()
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const print = params.get("print")
    const sessionId = params.get("sessionId")

    const {
        error,
        depositAccountDetails,
        loadDepositAccountDetails,
        createDeposit,
        createDepositError,
        depositPaymentDetails,
        loadDepositSessionResult,
        depositSessionResult,
        clearSessionResult,
        depositAccountDetailsError
    } = useViewModel()
    const [loading, setLoading] = useState(true)
    const [title, setTitle] = useState(null)
    const [description, setDescription] = useState(null)
    const [amount, setAmount] = useState(null)
    const [amountFontSize, setAmountFontSize] = useState('80px')
    const [retryReferenceCode, setRetryReferenceCode] = useState(null)

    useEffect(() => {
        if (depositAccountDetails) {
            setLoading(false)
            if (print) {
                setTimeout(function () {
                    printButtonRef.current.click()
                }, 1500)
            }
        } else if (!sessionId) {
            setTimeout(function () {
                loadDepositAccountDetails(referenceCode)
            }, 500)
        } else {
            setTimeout(function () {
                loadDepositSessionResult(sessionId)
            }, 3000)
        }
    }, [depositAccountDetails])

    useEffect(() => {
        if (depositPaymentDetails) {
            window.location = depositPaymentDetails.link
        }
    }, [depositPaymentDetails])

    const navigate = useNavigate();

    useEffect(() => {
        if (depositAccountDetailsError) {
            navigate('/?error=true')
        }
    }, [depositAccountDetailsError])

    useEffect(() => {
        if (depositSessionResult) {
            setRetryReferenceCode(depositSessionResult.referenceCode)
            setTitle(depositSessionResult.title)
            setDescription(depositSessionResult.description)
            setAmount(depositSessionResult.amount)
        }
    }, [depositSessionResult])

    useEffect(() => {
        if (amount?.toString().length > 3) {
            setAmountFontSize('48px')
        } else {
            setAmountFontSize('64px')
        }
    }, [amount])


    return (
        <Container className="deposit-background vh-100 vw-100 overflow-scroll">
            <main className="mx-auto ml-sm-auto px-md-4 pb-4 w-100 h-100 overflow-scroll">
                {
                    loading ? <CardLoading/> : getContent()
                }
                {
                    loading ? null : getQRCodeSection()
                }
            </main>
        </Container>
    )

    function getContent() {
        return (
            <Col className="deposit-col mx-auto">
                <Row className={"position-relative justify-content-center"}>
                    <Image
                        className={"deposit-header"}
                        src={header}
                    />
                </Row>
                {
                    depositSessionResult && depositSessionResult.authorised === false ? getDepositErrorPage() : getDepositHeaderSection()
                }
                {
                    depositSessionResult ? null : getCreateDepositSection()
                }
            </Col>
        )
    }

    function getQRCodeSection() {
        return (
            <Col className="mx-auto w-50 d-none">
                <Row className={"justify-content-center my-auto"} ref={componentRef}>
                    <Row className={"h-25"} style={{minHeight: '48px'}}/>
                    <Row className={"position-relative justify-content-center"}>
                        <Image
                            className={"deposit-header"}
                            src={header}
                        />
                    </Row>
                    <div className="d-flex justify-content-center position-relative mt-5">
                        <Image className={"deposit-avatar"}
                               src={iconUrlOrDefault(depositAccountDetails?.photo)}/>
                        <Image className={"deposit-avatar-star"}
                               src={avatarStar}/>
                    </div>
                    <span className={"deposit-avatar-text my-1"}>{depositAccountDetails?.name?.toUpperCase()}</span>
                    <span className={"qr-code-scan-text text-center mt-3 mb-5"}>SCAN BELOW TO PAY BY CARD</span>
                    <QRCode value={window.location.href.split('?')[0]} size={256} logoImage={senditLogo} logoWidth={128}
                            logoHeight={46} qrStyle={"dots"} quietZone={10}
                            ecLevel={"H"}
                            enableCORS={true}
                            removeQrCodeBehindLogo={false}
                            eyeRadius={[
                                [10, 10, 0, 10], // top/left eye
                                [10, 10, 10, 0], // top/right eye
                                [10, 0, 10, 10], // bottom/left
                            ]}
                            eyeColor={"#57CFFF"}
                            bgColor={"#FFF"}
                            fgColor={"#FC8FE0"}/>
                    <span className={"deposit-input text-center mt-5 pt-5"}>Send to anyone, anywhere, anytime</span>
                    <span className={"deposit-input text-center"}>Powered by SquareOne</span>
                    <Image src={logo} className={"qr-code-app-logo"}/>
                    {/*<Row className={"mt-2 p-0 justify-content-center text-center w-50 pt-5"}>*/}
                    {/*    <Col>*/}
                    {/*        <QRCode value={"https://apps.apple.com/nz/app/id1553399999"}*/}
                    {/*                qrStyle={"squares"}/>*/}
                    {/*        <Image*/}
                    {/*            className={"button-store p-0 mx-2"}*/}
                    {/*            src={buttonAppleStore}/>*/}
                    {/*    </Col>*/}
                    {/*    <Col>*/}
                    {/*        <QRCode value={"https://play.google.com/store/apps/details?id=app.getsquareone"}*/}
                    {/*                qrStyle={"squares"}/>*/}
                    {/*        <Image*/}
                    {/*            className={"button-store p-0 mx-2"}*/}
                    {/*            src={buttonGooglePlay}/>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                </Row>
            </Col>
        )
    }

    function getDepositErrorPage() {
        return (
            <Row className={"mt-4"}>
                 <span className={"deposit-info-title p-0 mt-4 mb-4"}>
                        Sorry, your payment was declined. You can use the button below to try again.
                 </span>

                <button className={"deposit-button-send mt-4 mx-auto"} onClick={() => {
                    clearSessionResult()
                }}>
                    Try again
                </button>
            </Row>
        )
    }

    function getDepositHeaderSection() {
        return (
            <Row className={"mt-3 position-relative"}>
                <div className="d-flex justify-content-center position-relative">
                    <Image className={"deposit-avatar"}
                           src={iconUrlOrDefault(depositAccountDetails?.photo)}/>
                    <Image className={"deposit-avatar-star"}
                           src={avatarStar}/>
                </div>
                <span className={"deposit-avatar-text my-1"}>{depositAccountDetails?.name?.toUpperCase()}</span>
                {
                    depositSessionResult?.authorised ? null :
                        <Row className={"d-none"}>
                            <ReactToPrint
                                trigger={() => <button ref={printButtonRef}/>}
                                content={() => componentRef.current}
                            />
                        </Row>
                }
                <span className={"deposit-info-title p-0"}>
                        {depositSessionResult?.authorised ? "All done!" : "How much are you sending?"}
                    </span>
                {
                    depositSessionResult?.authorised ?
                        <div>
                            <div className={"d-flex justify-content-center position-relative my-2"}>
                            <span className={"deposit-info-title p-0"}>
                                {'$' + depositSessionResult?.amount + ' has been sent. Thanks for using SendIt.'}
                            </span>
                            </div>
                            <button className={"deposit-button-send mt-4 mx-auto w-100"} onClick={() => {
                                navigate("/")
                            }}>
                                Return
                            </button>
                        </div> : getDepositInputSection()
                }
            </Row>
        )
    }

    function getDepositInputSection() {
        return (
            <div className={"d-flex justify-content-center position-relative my-2"}>
                <Image
                    className={"deposit-info-currency"}
                    src={currency}/>
                <input
                    className={"deposit-input-amount"}
                    type={"number"}
                    min={0.0}
                    key={amount}
                    max={9999}
                    autoFocus={true}
                    disabled={depositSessionResult}
                    style={{fontSize: amountFontSize}}
                    value={amount}
                    onChange={(e) => {
                        // alert()
                        if (isNaN(Number.parseFloat(e.target.value)) === false) {
                            if (e.target.value.startsWith('0') && !e.target.value.includes('.')) {
                                setAmount(Number.parseFloat(e.target.value))
                            } else {
                                if (e.target.value.includes('.')) {
                                    let decimalSplit = e.target.value.split('.')
                                    if (decimalSplit[1]?.length > 2) {
                                        setAmount(Number.parseFloat(e.target.value).toFixed(2))
                                    } else {
                                        setAmount(e.target.value)
                                    }
                                } else {
                                    setAmount(e.target.value)
                                }
                            }
                        } else {
                            setAmount(null)
                        }
                    }}/>
                <line className={"deposit-input-amount-line"}/>
            </div>
        )
    }

    function getCreateDepositSection() {
        return (
            <Row>
                <span className={"deposit-input-title-label p-0"}>From</span>
                <InputGroup className={"p-0 mt-2"}>
                    <FormControl
                        className={"deposit-input"}
                        value={title}
                        placeholder={""}
                        isInvalid={isTextNullOrEmpty(title)}
                        isValid={!isTextNullOrEmpty(title)}
                        onChange={(e) => {
                            setTitle(e.target.value)
                        }}
                    />
                </InputGroup>
                <span className={"deposit-input-message-label p-0 mt-3"}>Add an optional message</span>
                <InputGroup className={"p-0 mt-2"} style={{minHeight: '92px'}}>
                    <FormControl
                        className={"deposit-input"}
                        as="textarea"
                        placeholder={"Personalise the transfer with an optional message. You can enter up to 200 characters and include emoji’s! 😛"}
                        value={description}
                        maxLength={200}
                        onChange={(e) => {
                            setDescription(e.target.value)
                        }}
                    />
                </InputGroup>
                {
                    createDepositError ?
                        <Alert variant={"danger"} className={"deposit-error mt-3 p-2"}>
                            Sorry, we’re unable to send money to {depositAccountDetails?.name}’s account right now
                        </Alert> : null
                }
                <button className={"deposit-button-send mt-4 mx-auto mb-4"} disabled={
                    isTextNullOrEmpty(amount) || isTextNullOrEmpty(title) || amount <= 0
                } onClick={() => {
                    createDeposit(
                        retryReferenceCode ? retryReferenceCode : referenceCode,
                        amount,
                        title,
                        description)
                }}>
                    Send it
                </button>
                <Row className={"h-25"} style={{minHeight: '48px'}}/>
            </Row>
    )
    }

}