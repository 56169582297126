export default class DepositSessionResultDTO {

    constructor(referenceCode, amount, authorised, completed, title, description) {
        this.referenceCode = referenceCode
        this.amount = amount
        this.authorised = authorised
        this.completed = completed
        this.title = title
        this.description = description
    }

    static fromJSON(json): DepositSessionResultDTO {
        return Object.assign(new DepositSessionResultDTO(), json)
    }

}