import {useState} from "react";
import DepositAccountService from "../../domain/DepositAccountService";
import DepositAccountDetailsDTO from "../../data/DepositAccountDetailsDTO";
import DepositSessionResultDTO from "../../data/DepositSessionResultDTO";

export default function DepositDetailsViewModel() {

    const [error, setError] = useState(null);
    const [createDepositError, setCreateDepositError] = useState(null);
    const [depositAccountDetails: DepositAccountDetailsDTO, setDepositAccountDetails] = useState(null)
    const [depositPaymentDetails, setDepositPaymentDetails] = useState(null)
    const [depositAccountDetailsError, setDepositAccountDetailsError] = useState(null)
    const [depositSessionResult: DepositSessionResultDTO, setDepositSessionResult] = useState(null)

    function loadDepositAccountDetails(referenceCode) {
        DepositAccountService.instance.getDepositAccountDetails(referenceCode).then((result) => {
            if (result.isSuccessful()) {
                setDepositAccountDetails(result.data)
            } else {
                setDepositAccountDetailsError(result.error)
            }
        })
    }

    function loadDepositSessionResult(sessionId) {
        DepositAccountService.instance.getDepositSessionResult(sessionId).then((result) => {
            if (result.isSuccessful()) {
                if(result.data.completed) {
                    loadDepositAccountDetails(result.data.referenceCode)
                }

                setDepositSessionResult(result.data)
            } else {
                setError(result.error)
            }
        })
    }

    function clearSessionResult() {
        setDepositSessionResult(null)
    }

    function createDeposit(referenceCode, amount, title, description) {
        DepositAccountService.instance.createDeposit(referenceCode, amount, title, description).then((result) => {
            if (result.isSuccessful()) {
                setDepositPaymentDetails(result.data)
            } else {
                setCreateDepositError(result.error)
            }
        })
    }

    return {
        error,
        depositAccountDetails,
        loadDepositAccountDetails,
        depositPaymentDetails,
        createDeposit,
        createDepositError,
        loadDepositSessionResult,
        depositSessionResult,
        clearSessionResult,
        depositAccountDetailsError
    }
}