import BaseResponse from "./base/BaseResponse";
import BaseRequest from "./base/BaseRequest";
import DepositAccountDetailsDTO from "../data/DepositAccountDetailsDTO";
import DepositPaymentDetailsDTO from "../data/DepositPaymentDetailsDTO";
import DepositSessionResultDTO from "../data/DepositSessionResultDTO";

export default class DepositAccountApi {
    static instance = new DepositAccountApi()

    getDepositAccountDetails(referenceCode): Promise<BaseResponse<DepositAccountDetailsDTO>> {
        return new BaseRequest().executeRequest(
            '/api/v1/deposit/external/details',
            'POST',
            {
                referenceCode: referenceCode
            }
        ).then((baseResponse) => {
            if (baseResponse.isSuccessful()) {
                return new BaseResponse(baseResponse.data, null)
            } else {
                return new BaseResponse(null, baseResponse.error)
            }
        })
    }

    createDeposit(referenceCode, amount, title, description): Promise<BaseResponse<DepositPaymentDetailsDTO>> {
        return new BaseRequest().executeRequest(
            '/api/v1/deposit/external/create',
            'POST',
            {
                referenceCode: referenceCode,
                amount: Number(amount),
                title: title,
                description: description
            }
        ).then((baseResponse) => {
            if (baseResponse.isSuccessful()) {
                return new BaseResponse(baseResponse.data, null)
            } else {
                return new BaseResponse(null, baseResponse.error)
            }
        })
    }

    getDepositSessionResult(sessionId): Promise<BaseResponse<DepositSessionResultDTO>> {
        return new BaseRequest().executeRequest(
            '/api/v1/deposit/external/session',
            'POST',
            {
                sessionId: sessionId
            }
        ).then((baseResponse) => {
            if (baseResponse.isSuccessful()) {
                return new BaseResponse(DepositSessionResultDTO.fromJSON(baseResponse.data), null)
            } else {
                return new BaseResponse(null, baseResponse.error)
            }
        })
    }
}