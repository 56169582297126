import {BrowserRouter, HashRouter, Route, Routes} from "react-router-dom";
import './App.css';
import {Row} from "react-bootstrap";
import React from "react";
import DepositDetailsView from "./presentation/home/DepositDetailsView";
import WelcomeView from "./presentation/home/WelcomeView";

function App() {
    return (
        <BrowserRouter>
            <div className="app">
                <Row>
                    <Routes>
                        <Route exact path="/" element={<WelcomeView/>}/>
                        <Route exact path="/:referenceCode" element={<DepositDetailsView/>}/>
                    </Routes>
                </Row>
            </div>
        </BrowserRouter>
    );
}

export default App;
