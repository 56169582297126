import RequestError from "./RequestError";

export default class BaseResponse<T> {

    data: T = null
    error = null

    constructor(data: T, error) {
        if (error !== null) {
            if(error instanceof RequestError) {
                this.error = error
            } else {
                this.error = new RequestError(500, error)
            }
        } else {
            if (data !== undefined && data !== null) {
                this.data = data
            }
        }
    }

    isSuccessful() {
        return this.error === null || this.error === undefined
    }

}

export const convertToResponse = (result, error, httpCode) => {
    let requestError

    if (httpCode !== 200) {
        requestError = new RequestError(httpCode, result)
    } else {
        requestError = error
    }

    return new BaseResponse(result, requestError)
}