import BaseResponse from "../api/base/BaseResponse";
import DepositAccountDetailsDTO from "../data/DepositAccountDetailsDTO";
import DepositAccountApi from "../api/DepositAccountApi";
import DepositPaymentDetailsDTO from "../data/DepositPaymentDetailsDTO";
import DepositSessionResultDTO from "../data/DepositSessionResultDTO";

export default class DepositAccountService {
    static instance = new DepositAccountService()

    getDepositAccountDetails(referenceCode) : Promise<BaseResponse<DepositAccountDetailsDTO>> {
        return DepositAccountApi.instance.getDepositAccountDetails(referenceCode)
    }

    createDeposit(referenceCode, amount, title, description) : Promise<BaseResponse<DepositPaymentDetailsDTO>> {
        return DepositAccountApi.instance.createDeposit(referenceCode, amount, title, description)
    }

    getDepositSessionResult(sessionId) : Promise<BaseResponse<DepositSessionResultDTO>> {
        return DepositAccountApi.instance.getDepositSessionResult(sessionId)
    }
}